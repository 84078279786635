<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
            <template slot-scope="{ row }" slot="LevelOrder">
                {{ row.level_order }}
            </template>
            <template slot-scope="{ row }" slot="ApproverType">
                {{ row.approver_type ? row.approver_type.approver_type : "" }}
            </template>
            <template slot-scope="{ row }" slot="Approver">
                <select
                    v-model="row.approver_type_value"
                    class="form-control"
                    disabled
                >
                    <template v-if="row.approver_type_id == 5">
                        <option
                            v-for="(logicalPosition, index) in logicalPositions"
                            :key="index"
                            :value="logicalPosition.position_id"
                            >{{ logicalPosition.position_name_en }}
                        </option>
                    </template>

                    <template v-if="row.approver_type_id == 6">
                        <option
                            v-for="(employee, index) in employees"
                            :key="index"
                            :value="employee.employee_id"
                            >{{ employee.employee_name_en }}
                        </option>
                    </template>

                    <template v-if="row.approver_type_id == 7">
                        <option
                            v-for="(jt, index) in jobTitles"
                            :key="index"
                            :value="jt.job_title_id"
                            >{{ jt.job_title_en }}
                        </option>
                    </template>
                </select>
            </template>
            <template slot-scope="{ row }" slot="ApproverTypeCC">
                {{ row.approver_type_cc ? row.approver_type_cc.approver_type : "" }}
            </template>
            <template slot-scope="{ row }" slot="ApproverCC">
                <select
                    v-model="row.approver_type_cc_value"
                    class="form-control"
                    disabled
                >
                    <template v-if="row.approver_type_cc_id == 5">
                        <option
                            v-for="(logicalPosition, index) in logicalPositions"
                            :key="index"
                            :value="logicalPosition.position_id"
                            >{{ logicalPosition.position_name_en }}
                        </option>
                    </template>

                    <template v-if="row.approver_type_cc_id == 6">
                        <option
                            v-for="(employee, index) in employees"
                            :key="index"
                            :value="employee.employee_id"
                            >{{ employee.employee_name_en }}
                        </option>
                    </template>
                    <template v-if="row.approver_type_cc_id == 7">
                        <option
                            v-for="(jt, index) in jobTitles"
                            :key="index"
                            :value="jt.job_title_id"
                            >{{ jt.job_title_en }}
                        </option>
                    </template>
                </select>
            </template>
        </Table>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    props: {
        row: Object
    },
    computed: {
        ...mapState("setting/approvalFlow", ["employees", "logicalPositions", 'jobTitles']),
        resources() {
            return this.row.approval_request_approve_level;
        },
        columns() {
            return [
                {
                    title: this.$t("approvalFlow.levelOrder"),
                    slot: "LevelOrder"
                },
                {
                    title: this.$t("approvalFlow.approverType"),
                    slot: "ApproverType"
                },
                {
                    title: this.$t("approvalFlow.approver"),
                    slot: "Approver"
                },
                {
                    title: this.$t("approvalFlow.approverTypeCC"),
                    slot: "ApproverTypeCC"
                },
                {
                    title: this.$t("approvalFlow.approverCC"),
                    slot: "ApproverCC"
                }
            ];
        }
    }
};
</script>
