<template>
    <div>
        <ts-page-title
            :title="$t('approvalFlow.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('approvalFlow.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('approvalFlow.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="approval_type">
                    {{ row.approval_type ? row.approval_type.app_types : "" }}
                </template>
                <template slot-scope="{ row }" slot="is_enabled">
                    <i-switch
                        v-tooltip="$t('approvalFlow.isActive')"
                        size="small"
                        v-model="row.is_enabled"
                        :loading="row._switching"
                        @on-change="onChangeIsBspl(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1000"
                :title="$t('approvalFlow.pageTitle')"
                width="1300"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState, mapActions } from "vuex";
import FromAction from "./form";
import expandRow from "./table-expand.vue";
import { Errors } from "form-backend-validation";

export default {
    name: "approvalFlowIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            visible: false,
            width: "width: 300px",
            errors: new Errors()
        };
    },
    computed: {
        ...mapState("setting/approvalFlow", [
            "resources",
            "pagination",
            "employees",
            "jobTitles",
            "logicalPositions"
        ]),
        search: {
            get() {
                return this.$store.state.setting.approvalFlow.search;
            },
            set(newValue) {
                this.$store.commit("setting/approvalFlow/SET_SEARCH", newValue);
                this.$store.commit("setting/approvalFlow/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    type: "expand",
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        });
                    },
                    minWidth: 30
                },
                {
                    title: this.$t("approvalFlow.approvalType"),
                    slot: "approval_type",
                    minWidth: 150
                },
                {
                    title: this.$t("approvalFlow.escalateAfterHours"),
                    key: "escalate_after_xx_hours",
                    minWidth: 180,
                    align: "center"
                },
                {
                    title: this.$t("approvalFlow.numberOfEscalate"),
                    key: "escalate_times",
                    minWidth: 180,
                    align: "center"
                },
                {
                    title: this.$t("approvalFlow.approverMobileTemplate"),
                    key: "approver_notification_template",
                    minWidth: 250
                },
                {
                    title: this.$t("approvalFlow.initiatorMobileTemplate"),
                    key: "initiator_notification_template",
                    minWidth: 250
                },
                {
                    title: this.$t("approvalFlow.isEnabled"),
                    slot: "is_enabled",
                    align: "center",
                    minWidth: 100
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        ...mapActions("setting/approvalFlow", [
            "getLogicalPosition",
            "getJobTitle",
            "getEmployee"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getLogicalPosition();
            await this.getJobTitle();
            await this.getEmployee();
            this.loading = false;
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("setting/approvalFlow/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$refs.form_action.clearInput();
            this.$store.commit("setting/approvalFlow/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
            this.$store.commit("setting/approvalFlow/SET_EDIT_DATA", {});
        },
        addNew() {
            this.$store.commit("setting/approvalFlow/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
        },
        async onChangeIsBspl(record) {
            record._switching = true;
            this.$store
                .dispatch("setting/approvalFlow/changeIsEnabled", {
                    id: record.app_flow_id,
                    data: { is_enabled: record.is_enabled }
                })
                .then(response => {
                    this.fetchData();
                    record._switching = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._switching = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "APPROVAL FLOW",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({
                search
            });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("setting/approvalFlow/RESET_STATE");
        this.$store.commit("setting/approvalFlow/CLEAR_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
            vm.fetchData();
        });
    }
};
</script>
